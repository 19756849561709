import { Elements } from "@stripe/react-stripe-js"
import Checkout from "../Checkout/Checkout";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SelectedHotel } from "../../types/SelectedHotel";
import { HoperfyEvent, PaymentIntentRequest, RoomAmount, createPaymentIntent, getEvent } from "../../clients/DirectusClient";
import { loadStripe } from "@stripe/stripe-js";
import Navigation from "../Navigation/Navigation";
import LoadingBar from "../LoadingBar/LoadingBar";
import Big from "big.js";

interface CheckoutParentProps {

}
const CheckoutParent: FC<CheckoutParentProps> = () => {
    const location = useLocation();
    const selectedHotel = location.state as SelectedHotel;
    const [clientSecret, setClientSecret] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [amount, setAmount] = useState<Big>(new Big(0));
    const [event, setEvent] = useState<HoperfyEvent>();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

    const { eventSlug } = useParams();

    const getPaymentIntent = async (eventSlug: string) => {
        const event = await getEvent(eventSlug);
        setEvent(event);

        const paymentIntentRequest = {
            hotelId: selectedHotel.hotel.id,
            rooms: selectedHotel.rooms.map(x => ({
                roomId: x.roomId,
                amount: x.amount
            })) as RoomAmount[],
            search: selectedHotel.search,
            eventId: event.id
        } as PaymentIntentRequest


        createPaymentIntent(paymentIntentRequest)
            .then((data) => {
                setClientSecret(data.clientSecret)
                setAmount(new Big(data.amount))
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {

        setLoading(true)
        getPaymentIntent(eventSlug || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="flex-1 flex-flex-col">
            {event &&
                <Navigation event={event} />
            }

            <div className="bg-white -mt-10 rounded-xl relative z-10 flex-1">


                <LoadingBar loading={loading} />


                {clientSecret && amount &&
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <Checkout selectedHotel={selectedHotel} totalPrice={amount} clientSecret={clientSecret} event={event!} />
                    </Elements>
                }

            </div>

        </div>
    )
}
export default CheckoutParent;