import { FC } from "react";
import HotelList from "../components/HotelList/HotelList";


const HotelListPage: FC = () => {

    return (
        <div>
            <HotelList />
        </div>
    );
};

export default HotelListPage;