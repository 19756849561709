import { FC } from "react";
import CheckoutParent from "../components/CheckoutParent/CheckoutParent";


const CheckoutPage: FC = () => {


    return (
        <div className="min-h-screen flex flex-col">
            <CheckoutParent />
        </div>
    );
};

export default CheckoutPage;