import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CheckoutPage from './pages/CheckoutPage';
import HotelListPage from './pages/HotelListPage';
import HotelViewPage from './pages/HotelViewPage';
import SuccessPage from './pages/SuccessPage';
import EventListPage from './pages/EventListPage';
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module';
import Hotjar from './components/Hotjar/Hotjar';

const App: React.FC = () => {
  ReactGA.initialize('G-5L779EW51V')

  const tagManagerArgs = {
    gtmId: 'GTM-54SDGG83'
  };
  TagManager.initialize(tagManagerArgs);
  return (
    <BrowserRouter>
      <Hotjar />
      <Routes>
        <Route path="/" element={<EventListPage />} />
        <Route path="/:eventSlug/hotels" element={<HotelListPage />} />
        <Route path="/:eventSlug/hotel/:hotelId" element={<HotelViewPage />} />
        <Route path="/:eventSlug/checkout" element={<CheckoutPage />} />
        <Route path='/:eventSlug/success' element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
};


export default App;
