import { FC } from "react";
import HotelView from "../components/HotelView/HotelView";


const HotelViewPage: FC = () => {

    return (
        <div>
           <HotelView />
        </div>
    );
};

export default HotelViewPage;