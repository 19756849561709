import { FC } from "react";
import Success from "../components/Success/Success";


const SuccessPage: FC = () => {


    return (
        <Success />
    );
};

export default SuccessPage;