import React, { FC, useEffect, useState } from 'react';
import './HotelList.css';
import { geoSearch, GeoSearchRequest, HotelResponse } from '../../clients/HotelsClient';
import { Link, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../Footer/Footer";
import { constructUrl, getSearchParams } from '../../utils/QueryParamUtil';
import LoadingBar from '../LoadingBar/LoadingBar';
import { HoperfyEvent, getEvent } from '../../clients/DirectusClient';
import Big from 'big.js';
import { getTotalPrice } from '../../utils/PriceUtils';
import Navigation from '../Navigation/Navigation';


interface HotelListProps { }

interface HotelDetails extends HotelResponse {
  totalStartingFrom: Big
  totalDailyStartingFrom: Big;
  distanceToVenue: number
}

interface SearchFilter {
  stars: number[]
}

enum SortingOrder {
  RECOMMENDED = "RECOMMENDED",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
}

const HotelList: FC<HotelListProps> = () => {


  const [checkIn, setScheckIn] = useState<Date>()
  const [checkOut, setCheckOut] = useState<Date>()
  const [adults, setAdults] = useState<number>(2);
  const [filter, setFilter] = useState<SearchFilter>({ stars: [] });

  const [hotels, setHotels] = useState<HotelDetails[] | null>(null);
  const [totalHotels, setTotalHotels] = useState<HotelDetails[] | null>(null);
  const [originalHotels, setOriginalHotels] = useState<HotelDetails[] | null>(null);
  const [event, setEvent] = useState<HoperfyEvent>();
  const [geoSearchRequest, setGeoSearchRequest] = useState<GeoSearchRequest>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(SortingOrder.RECOMMENDED);


  const { eventSlug } = useParams();


  const [error, setError] = useState<string | null>(null);

  const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return Math.round(d * 100) / 100;
  }

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  const sortHotels = (hotels: HotelDetails[]): HotelDetails[] => {
    const hotelIndexMap = new Map<HotelDetails, number>();

    console.log(originalHotels?.[0].hotel.name);
    // Create a map of originalHotels with their indices
    originalHotels?.forEach((hotel, index) => {
      hotelIndexMap.set(hotel, index);
    });

    switch (sortingOrder) {
      case SortingOrder.PRICE_ASC:
        return hotels.sort((a, b) => a.totalStartingFrom.minus(b.totalStartingFrom).toNumber());
      case SortingOrder.PRICE_DESC:
        return hotels.sort((a, b) => b.totalStartingFrom.minus(a.totalStartingFrom).toNumber());
      case SortingOrder.DISTANCE_ASC:
        return hotels.sort((a, b) => a.distanceToVenue - b.distanceToVenue);
      case SortingOrder.DISTANCE_DESC:
        return hotels.sort((a, b) => b.distanceToVenue - a.distanceToVenue);
      default:
        return hotels.sort((a, b) => (hotelIndexMap.get(a) || 0) - (hotelIndexMap.get(b) || 0)) || hotels;
    }
  };


  const handleCheckboxChange = (star: number, isChecked: boolean) => {
    setFilter(prevFilter => {
      const updatedStars = isChecked
        ? [...prevFilter.stars, star] // Add star rating if checked
        : prevFilter.stars.filter(s => s !== star); // Remove star rating if unchecked
      return { ...prevFilter, stars: updatedStars };
    })
  };



  const fetchHotels = async (adults: number, slug: string) => {
    setLoading(true);
    setError(null);


    try {
      const event = await getEvent(slug);
      setEvent(event)

      if (!checkIn) {
        setScheckIn(event.start_date)
      }
      if (!checkOut) {
        setCheckOut(event.end_date)
      }
      const newRequest = {
        longitude: event.longitude,
        latitude: event.latitude,
        radius: event.radius,
        checkIn: checkIn || event.start_date,
        checkOut: checkOut || event.end_date,
        rooms: [{ adults: adults, children: [] }]
      }

      setGeoSearchRequest(newRequest)

      const result = await geoSearch(newRequest);
      const enrichedResult = result.map(x => ({
        ...x,
        totalStartingFrom: getTotalPrice(x.startingFrom, event),
        totalDailyStartingFrom: getTotalPrice(x.dailyPricesStartFrom, event),
        distanceToVenue: getDistanceFromLatLonInKm(event.latitude, event.longitude, x.hotel.contactDTO.coordinatesDTO.latitude, x.hotel.contactDTO.coordinatesDTO.longitude)
      }) as HotelDetails)
      setHotels(enrichedResult);
      setOriginalHotels(enrichedResult)
      setTotalHotels(enrichedResult)
    } catch (error) {
      setError('Error searching hotels');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHotels(adults, eventSlug || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hotels) {
      const sortedHotels = sortHotels([...hotels]);

      setHotels(sortedHotels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOrder]);

  useEffect(() => {
    if (hotels) {
      if (filter.stars && filter.stars.length > 0) {
        const filteredhotels = totalHotels?.filter(x => filter?.stars.includes(x.hotel.stars))
        const sortedFilteredHotels = sortHotels(filteredhotels || [])
        setHotels(sortedFilteredHotels)
      } else {
        console.log(totalHotels?.[0].hotel.name)
        const sortedFilteredHotels = sortHotels([...totalHotels || []]);
        setHotels(sortedFilteredHotels)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);


  return (
    <div className="antialiased h-screen">

      {event &&
        <Navigation event={event} />
      }
      <main className="bg-white rounded-t-2xl -mt-10 flex flex-col items-center px-10 py-10 relative z-10">

        <section className="w-full max-w-screen-2xl py-6">

          {event &&
            <h1 className="tracking-tighter text-5xl">Hotels in <span className="font-bold">{event?.city}</span> near <span className="font-bold">{event?.name}</span></h1>
          }
        </section>

        <section className="w-full max-w-screen-2xl mt-6 relative z-20">

          <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between gap-4">

            <div className="flex-1 flex lg:flex-row flex-col lg:items-center gap-4">

              <div className="w-full lg:max-w-[240px] flex flex-col pt-3.5 relative">
                <span className="text-xs opacity-60 px-5 relative z-10">Check-in</span>
                <DatePicker className="font-medium text-sm bg-neutral-100 pb-3.5 pt-8 px-5 -mt-8 w-full focus:outline-none focus:bg-white rounded-2xl border border-transparent focus:border-neutral-200 leading-5" selected={checkIn} onChange={(date) => setScheckIn(date || checkIn)} />
              </div>

              <div className="w-full lg:max-w-[240px] flex flex-col pt-3.5 relative">
                <span className="text-xs opacity-60 px-5 relative z-10">Check-out</span>
                <DatePicker className="font-medium text-sm bg-neutral-100 pb-3.5 pt-8 px-5 -mt-8 w-full focus:outline-none focus:bg-white rounded-2xl border border-transparent focus:border-neutral-200 leading-5" selected={checkOut} onChange={(date) => setCheckOut(date || checkOut)} />
              </div>

              <div className="w-full lg:max-w-[140px] bg-neutral-100 border border-transparent focus-within:border-neutral-200 focus-within:bg-white flex flex-col px-5 py-3.5 rounded-2xl">
                <span className="text-xs opacity-60 my-px">Guests per room</span>
                <input defaultValue={adults} onChange={e => setAdults(+e.target.value)} type="number" className="font-medium text-sm p-0 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5" />
              </div>

              <button onClick={(e: any) => fetchHotels(adults, eventSlug || '')} className="p-5 bg-black hover:bg-black/70 cursor-pointer rounded-2xl text-white flex items-center justify-center text-sm font-medium gap-1.5 leading-5" disabled={adults <= 0 || loading || checkIn == null || checkOut == null}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd"></path>
                </svg>
                <span className="md:hidden text-base font-medium">Search</span>
              </button>

            </div>

            <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl min-w-[220px] relative">
              <label className="text-xs opacity-70 my-px pointer-events-none">Sort by</label>
              <select
                className="appearance-none font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400 max-w-[220px] truncate"
                value={sortingOrder}
                onChange={(e) => setSortingOrder(e.target.value as SortingOrder)}
              >
                <option value={SortingOrder.RECOMMENDED}>Recommended</option>
                <option value={SortingOrder.PRICE_ASC}>Price (lowest first)</option>
                <option value={SortingOrder.PRICE_DESC}>Price (highest first)</option>
                <option value={SortingOrder.DISTANCE_ASC}>Distance (closest first)</option>
                <option value={SortingOrder.DISTANCE_DESC}>Distance (farthest first)</option>

              </select>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 absolute right-4 top-[28px] opacity-30"><path d="M18 9 12 3 6 9H18ZM18 15 12 21 6 15H18Z"></path></svg>
            </div>

          </div>

        </section>

        {loading && (
          <LoadingBar loading={loading} />
        )}
        {loading && (
          <section className="w-full max-w-screen-2xl grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12">

            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>
            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>
            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>

          </section>
        )}

        {error && <p>{error}</p>}

        {hotels && !loading && !error && (

          <section className="mt-12 flex w-full max-w-screen-2xl flex-col gap-12 md:flex-row">
            <div className="flex w-full flex-col gap-4 md:w-56 lg:w-64">
              <div className="flex flex-col">
                <span className="font-medium">Property rating</span>
                <span className="text-sm opacity-50">Find high-quality hotels.</span>
              </div>
              <div className="flex flex-col gap-2.5">
                <label className="flex items-center gap-2.5">
                  <input type="checkbox" className="h-5 w-5 rounded-md"
                    onChange={(e) => handleCheckboxChange(5, e.target.checked)} />
                  <span className="text-sm font-medium">5 stars</span>
                  <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x=> x.hotel.stars === 5).length || 0}</span>
                </label>
                <label className="flex items-center gap-2.5">
                  <input type="checkbox" className="h-5 w-5 rounded-md"
                    onChange={(e) => handleCheckboxChange(4, e.target.checked)} />
                  <span className="text-sm font-medium">4 stars</span>
                  <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x=> x.hotel.stars === 4).length || 0}</span>
                </label>
                <label className="flex items-center gap-2.5">
                  <input type="checkbox" className="h-5 w-5 rounded-md"
                    onChange={(e) => handleCheckboxChange(3, e.target.checked)} />
                  <span className="text-sm font-medium">3 stars</span>
                  <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x=> x.hotel.stars === 3).length || 0}</span>
                </label>
                <label className="flex items-center gap-2.5">
                  <input type="checkbox" className="h-5 w-5 rounded-md"
                    onChange={(e) => handleCheckboxChange(2, e.target.checked)} />
                  <span className="text-sm font-medium">2 stars</span>
                  <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x=> x.hotel.stars === 2).length || 0}</span>
                </label>
                <label className="flex items-center gap-2.5">
                  <input type="checkbox" className="h-5 w-5 rounded-md"
                    onChange={(e) => handleCheckboxChange(1, e.target.checked)} />
                  <span className="text-sm font-medium">1 star</span>
                  <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x=> x.hotel.stars === 1).length || 0}</span>
                </label>
              </div>
            </div>
            <div className="grid flex-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              {hotels.map((hotel, index) => (

                <Link className="flex flex-col bg-white rounded-[14px] relative group" key={`/${event?.slug}/hotel/${hotel.hotel.id}`} to={`/${event?.slug}/hotel/${hotel.hotel.id}?${getSearchParams(geoSearchRequest).toString()}`}>

                  <div className="bg-neutral-100 aspect-video rounded-[14px] overflow-hidden relative">
                    <div className="h-1/2 w-full bg-gradient-to-t from-black opacity-30 absolute bottom-0 z-10" />
                    {hotel.hotel.stars > 0 &&
                      <div className="flex items-center gap-0.5 absolute bottom-5 left-6 bg-white rounded-lg py-1 px-1.5 z-20 text-amber-300">
                        {Array.from(Array(hotel.hotel.stars), (e, i) => {
                          return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3.5">
                            <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd"></path>
                          </svg>
                        })}

                      </div>
                    }
                    <img src={constructUrl('1024x768', hotel.hotel.images[0])} alt={hotel.hotel.name} className="w-full h-full object-cover rounded-[14px] group-hover:scale-[1.02] transition ease-in-out" />
                  </div>

                  <div className="border border-black/5 group-hover:border-black/15 rounded-[14px] absolute w-full h-full z-10"></div>

                  <div className="flex-1 p-6 flex flex-col gap-1">

                    <div className="">
                      <h3 className="inline font-medium leading-4">{hotel.hotel.name}</h3>
                    </div>

                    {event && hotel.hotel.contactDTO.coordinatesDTO.latitude && hotel.hotel.contactDTO.coordinatesDTO.longitude &&
                      <div className="">
                        <span className="text-sm opacity-60">{hotel.distanceToVenue}km away from the venue</span>
                      </div>
                    }

                    <div className="flex items-center gap-2 mt-auto pt-3">
                      <span className="text-sm opacity-60">€{hotel.totalDailyStartingFrom.toString()} night</span>
                      <div className="h-0.5 w-0.5 bg-black/30"></div>
                      <span className="text-sm"><span className="font-medium">€{hotel.totalStartingFrom.toString()}</span> total</span>
                    </div>

                  </div>

                </Link>

              ))}
            </div>
          </section>
        )}
      </main>

      <Footer />

    </div>
  )
}

export default HotelList;
