import React, { FC, useEffect, useState } from "react";
import Navigation from "../Navigation/Navigation";
import {useParams} from "react-router-dom";
import { HoperfyEvent, getEvent } from "../../clients/DirectusClient";

interface SuccessProps {

}
const Success: FC<SuccessProps> = () => {

    const { eventSlug } = useParams();
    const [event, setEvent] = useState<HoperfyEvent>();

    useEffect(() => {
        fetchEvent(eventSlug || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchEvent = async (eventSlug: string) => {
        const event = await getEvent(eventSlug || '');
        setEvent(event);
    }

    return (
        <div className="flex flex-col min-h-screen">

            {event &&
            <Navigation event={event}/>
            }
        

            <div className="flex-1 flex flex-col items-center justify-center gap-2 w-full pb-20 bg-white -mt-10 rounded-xl relative z-10 flex-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 text-green-500">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd"/>
                </svg>
                <h2 className="font-semibold text-xl">Success!</h2>
                <span className="max-w-sm opacity-60 text-center">Your order has been successfully placed. We will reach out to you in few hours.</span>
            </div>
        
        </div>
    )
}
export default Success;