import { FC } from "react";
import EventList from "../components/EventList/EventList";


const EventListPage: FC = () => {

    return (
        <div>
            <EventList />
        </div>
    );
};

export default EventListPage;