import React, { FC, useEffect, useState } from 'react';

interface LoadingBarProps { loading: boolean }

const LoadingBar: FC<LoadingBarProps> = ({loading}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (loading) {
          interval = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress === 100) {
                clearInterval(interval);
                return 100;
              }
              const diff = (100 - oldProgress) / 10; // Slow down as progress increases

              return Math.min(oldProgress + diff, 100);
            });
          }, 500);
        } else {
          setProgress(100);
          setTimeout(() => setProgress(0), 200);
        }
        return () => {
          clearInterval(interval);
        };
      }, [loading]);

    return (<div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: `${progress}%`,
            height: '4px',
            backgroundColor: 'blue',
            transition: 'width 0.2s ease-out',
            zIndex: 1000,
        }}
    ></div>

)};

export default LoadingBar;
