import React, { FC } from 'react';

interface NavigationProps { }

const HotelView: FC<NavigationProps> = () => (

    <footer className="w-full py-16 flex flex-col items-center px-10">

        <div className="w-full max-w-screen-2xl">

            <hr className="w-full border-black/5 mb-16" />

            <div className="flex justify-center">

                <span className="text-xs opacity-60 text-center">2024 © Hoperfy. All rights reserved.</span>

            </div>

        </div>

    </footer>

);

export default HotelView;
