export const getSearchParams = (object: any): URLSearchParams => {
    return Object.keys(object).reduce((output, key) => {
    const value = object[key];
    const valueString = JSON.stringify(value);
    output.append(key, valueString);
    return output;
}, new URLSearchParams());
  }


  export const getObject = (urlSearchParams: URLSearchParams): any => {
    const result = {} as any
    urlSearchParams.forEach((value, name) => {
        result[name] = JSON.parse(value);
    });
    return result;
  }

  export const constructUrl = (size: string, url: string): string => {
    if (!url) {
      return "";
    }
    return url.replace("{size}", size);
  };

